import './styles/css/global.css'
import './styles/css/reusables.css'
import './styles/css/landing.css'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'


////////////////////////////////////////////////////
//////////////////// CANVAS ////////////////////////
////////////////////////////////////////////////////
const canvas = document.querySelector('canvas.canvas')

////////////////////////////////////////////////////
//////////////////// WINDOW ////////////////////////
////////////////////////////////////////////////////
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

////////////////////////////////////////////////////
//////////////////// SCENE /////////////////////////
////////////////////////////////////////////////////
const scene = new THREE.Scene()


////////////////////////////////////////////////////
//////////////////// MATERIALS /////////////////////
////////////////////////////////////////////////////
const GetTexture = new THREE.TextureLoader()

////////////////////////////////////////////////////
//////////////////// PARTICLES /////////////////////
////////////////////////////////////////////////////
const PartsGeometry = new THREE.BufferGeometry()
const PartsNo = 200
const PartsPosition = new Float32Array(PartsNo * 3)

PartsGeometry.setAttribute(
    'position',
    new THREE.BufferAttribute(PartsPosition, 3)
)

for (let i =0; i < PartsNo * 3; i++)
    {
        PartsPosition[i] = (Math.random() - .5) * 10
    }

const PartsMaterial = new THREE.PointsMaterial({
    size: .02,
    sizeAttenuation: true,
    color: "#02858d",
    depthWrite: false
})
const Ripple = new THREE.Points(PartsGeometry, PartsMaterial)
Ripple.position.set(0, 0, -15)
scene.add(Ripple)

////////////////////////////////////////////////////
//////////////////// OBJECTS ///////////////////////
////////////////////////////////////////////////////
const DDDLogoBubble = new THREE.Mesh(
    new THREE.SphereGeometry( 9.2, 64, 64 ),
    new THREE.MeshStandardMaterial({ 
        color: '#f5f5f5',
        transparent: true,
        opacity : 0.25,
        metalness: 1
    })
)
DDDLogoBubble.position.set(0, 0, -5);
scene.add(DDDLogoBubble)

const DDDLogoWhiteBubble = new THREE.Mesh(
    new THREE.SphereGeometry( 9.2, 64, 64 ),
    new THREE.MeshBasicMaterial({ 
        color: '#f5f5f5',
        transparent: true,
        opacity : 0.01,
        reflectivity: 0,
    })
)
DDDLogoWhiteBubble.position.set(0, 0, -5);
scene.add(DDDLogoWhiteBubble)


////////////////////////////////////////////////////
//////////////////// MODELS ////////////////////////
////////////////////////////////////////////////////
const GetDracoModels = new DRACOLoader()
GetDracoModels.setDecoderPath('./helper/draco/')
const GetModels = new GLTFLoader()
//LOGO
let DDDLogo = null
 GetModels.load(
    './models/DoitLogo.gltf',
    (GetDDDLogo) => {
        DDDLogo = GetDDDLogo.scene
        DDDLogo.scale.set( 0.1, 0.1, 0.1)
        DDDLogo.position.set(0, 0, -5)
        scene.add(DDDLogo)
        //debug
        // gui.add(DDDLogo.position, 'x').min(-3).max(3).step(0.01)
        // gui.add(DDDLogo.position, 'y').min(-3).max(3).step(0.01)
        // gui.add(DDDLogo.position, 'z').min(-10).max(10).step(0.01)
        // gui.add(DDDLogo, 'visible')
    }
)
//OFFICE
let DDDoffice = null
GetModels.load(
    './models/Office.glb',
    (GetDDDoffice) => {
        DDDoffice = GetDDDoffice.scene
        DDDoffice.traverse((node) => {
            if (!node.isMesh) return;
            node.material.wireframe = true
          });
        DDDoffice.position.set(-15, -250, -200)
        DDDoffice.scale.set(.5, .5, .5) 
        scene.add(DDDoffice)
    }
)

//Laptop
let DDDlaptop = null
GetModels.load(
    './models/Laptop.gltf',
    (GetDDDlaptop) => {
        DDDlaptop = GetDDDlaptop.scene
        DDDlaptop.position.set(25, -8, -72)
        DDDlaptop.scale.set(.1, .1, .1)
        DDDlaptop.rotation.z = -0.3
    }
)

//Like
let DDDlike = null
GetModels.load(
    './models/Like.gltf',
    (GetDDDlike) => {
        DDDlike = GetDDDlike.scene
        DDDlike.position.set(26, -8, -95)
        DDDlike.rotation.x = Math.PI
        DDDlike.scale.set(.1, .1, .1) 
    }
)

//Megaphone
let DDDmega = null
GetModels.load(
    './models/Megaphone.gltf',
    (GetDDDmega) => {
        DDDmega = GetDDDmega.scene
        DDDmega.position.set(10, -8, -92)
        DDDmega.rotation.x = Math.PI + .5
        DDDmega.rotation.y = Math.PI / 2 
        DDDmega.scale.set(.004, .004, .004) 
    }
)

//Camera
let DDDcamera = null
GetModels.load(
    './models/Camera.gltf',
    (GetDDDcamera) => {
        DDDcamera = GetDDDcamera.scene
        DDDcamera.position.set(-22, -9, -100)
        DDDcamera.scale.set(1, 1, 1) 
    }
)

//Eight
let DDDeight = null
GetModels.load(
    './models/Eightball.gltf',
    (GetDDDeight) => {
        DDDeight = GetDDDeight.scene
        DDDeight.position.set(-28, -7, -74.5)
        DDDeight.scale.set(.7, .7, .7) 
    }
)

////////////////////////////////////////////////////
//////////////////// LIGHTS ////////////////////////
////////////////////////////////////////////////////
const PinkLight = new THREE.PointLight('#D959B7', 1)
PinkLight.position.set(-10, 10, -5);
PinkLight.castShadow = true
if (DDDLogo != null){
    PinkLight.lookAt(DDDLogo)
}
scene.add(PinkLight)

const TurqLight = new THREE.PointLight('#75BEB8', 1)
TurqLight.position.set(10, -10, -5);
TurqLight.castShadow = true
if (DDDLogo != null){
    TurqLight.lookAt(DDDLogo)
}
scene.add(TurqLight)

const WhiteLightOne = new THREE.SpotLight('#f5f5f5', 1)
WhiteLightOne.position.set(100, -100, -50)
WhiteLightOne.castShadow = true
if (DDDLogo != null){
    WhiteLightOne.lookAt(DDDLogo)
}
scene.add(WhiteLightOne)

const WhiteLightTwo = new THREE.SpotLight('#f5f5f5', 1)
WhiteLightTwo.position.set(-100, 100, 50)
WhiteLightTwo.castShadow = true
if (DDDLogo != null){
    WhiteLightTwo.lookAt(DDDLogo)
}
scene.add(WhiteLightTwo)

const WhiteDirLightOne = new THREE.DirectionalLight('#f5f5f5', 1)
WhiteDirLightOne.position.set(100, -100, -50)
WhiteDirLightOne.castShadow = true
if (DDDLogo != null){
    WhiteDirLightOne.lookAt(DDDLogo)
}
scene.add(WhiteDirLightOne)

const WhiteDirLightTwo = new THREE.DirectionalLight('#f5f5f5', 1)
WhiteDirLightTwo.position.set(-100, 100, 50)
WhiteDirLightTwo.castShadow = true
if (DDDLogo != null){
    WhiteDirLightTwo.lookAt(DDDLogo)
}
scene.add(WhiteDirLightTwo)

const Ambient = new THREE.AmbientLight('#ffffff', 1)
// scene.add(Ambient)

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

////////////////////////////////////////////////////
//////////////////// CAMERA ////////////////////////
////////////////////////////////////////////////////
// Base camera
const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 0
camera.position.y = 0
camera.position.z = -10
scene.add(camera)

////////////////////////////////////////////////////
//////////////////// CONTROLS //////////////////////
////////////////////////////////////////////////////
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true

////////////////////////////////////////////////////
//////////////////// RENDERER //////////////////////
////////////////////////////////////////////////////
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

////////////////////////////////////////////////////
//////////////////// ON SCROLL /////////////////////
////////////////////////////////////////////////////
let Scroll = window.scrollY
window.addEventListener('scroll', () => {
    Scroll = window.scrollY 
})

////////////////////////////////////////////////////
//////////////////// PATH //////////////////////////
////////////////////////////////////////////////////
// SET TARGET
const TargetGeo = new THREE.BoxGeometry(1, 1, 1)
const TargetMat = new THREE.MeshStandardMaterial({
    color:'red',
    visible: false
})
const Target = new THREE.Mesh(TargetGeo, TargetMat)
scene.add(Target)


//SET PATH
const CurvePoints = [
    new THREE.Vector3( 0, -8, -10 ),
    new THREE.Vector3( 0, -8, -65 ),
    new THREE.Vector3( 30, -8, -75 ),
    new THREE.Vector3( 35, -8, -85 ),
    new THREE.Vector3( 30, -8, -90 ),
    new THREE.Vector3( 10, -8, -92 ),
    new THREE.Vector3( 5, -8, -90 ),
    new THREE.Vector3( 0, -8, -88 ),
    new THREE.Vector3( -20, -8, -88 ),
    new THREE.Vector3( -22, -8, -95 ),
    new THREE.Vector3( -30, -8, -95 ),
    new THREE.Vector3( -35, -8, -85 ),
    new THREE.Vector3( -30, -8, -75 ),
    new THREE.Vector3( -20, -8, -70 ),
    new THREE.Vector3(-12, -8, -70 ),
    new THREE.Vector3( -12, -8, -150 )
]
        
const Curve = new THREE.CatmullRomCurve3(CurvePoints);



////////////////////////////////////////////////////
//////////////////// CAPTURE ///////////////////////
////////////////////////////////////////////////////
let Time = Date.now()
const clock = new THREE.Clock()
const VideoCapture = () =>
{
    //Time
    // const elapsedTime = clock.getElapsedTime()
    const CurrentTime = Date.now()
    const TimeDif = CurrentTime - Time
    Time = CurrentTime

    const elapsedTime = clock.getElapsedTime()

    // Waves
    for (let i =0; i < PartsNo; i++)
    {
        const i3 = i * 3
        const x = PartsGeometry.attributes.position.array[i3 + 0]
        PartsGeometry.attributes.position.array[i3 + 1] = Math.sin(elapsedTime + x * 2) * 0.2
    }
    PartsGeometry.attributes.position.needsUpdate = true

    if (DDDLogo !== null){
        DDDLogo.rotation.x += .0008 * TimeDif
        DDDLogo.rotation.y += .001 * TimeDif

        if(Scroll < 1000){
            DDDLogo.position.z = - ((Scroll ) * .09 + 5)
            DDDLogoBubble.position.z = - ((Scroll) * .09 + 5)
            DDDLogoWhiteBubble.position.z = - ((Scroll) * .09 + 5)
            Ripple.position.y = -((Scroll) * 0.01) + 1
        } else if( Scroll > 1000 && Scroll < 2000 ){
            DDDLogo.position.y = (- 1000 * 0.002) + (Scroll - 1000) * 0.05
            DDDLogo.position.z = - 84
            DDDLogoBubble.position.y = (- 1000 * 0.002) + (Scroll - 1000) * 0.05
            DDDLogoBubble.position.z = - 84
            DDDLogoWhiteBubble.position.y = (- 1000 * 0.002) + (Scroll - 1000) * 0.05
            DDDLogoWhiteBubble.position.z = - 84
        }        
    }

    
    if (Scroll >  2450 && Scroll < 6450){
        const TargetPlay = (Scroll - 2450) / 4000
        // console.log("TargetPlay =", TargetPlay)
        const TargetPath = Curve.getPoint(TargetPlay)
        // console.log("TargetPath =", TargetPath)
        Target.position.copy(TargetPath)
    }

    if(Scroll > 6500){
        Target.position.set(-12, -8, -150)
    }

    if (Scroll > 2000 && Scroll < 2500){
        Target.position.set(0, -8, -10)
        camera.position.set(0, -8, -10)
    } else{
        camera.position.y = 0
    }

    
    if ( Scroll > 2500 && Scroll < 6500) {
        const CamPlay = (Scroll - 2500) / 4000
        const CamPath = Curve.getPoint(CamPlay)
        camera.position.copy(CamPath)
        camera.lookAt(Target.position)

        let i = 0 
        if (DDDlaptop != null && i == 0 && CamPlay > .055){
            scene.add(DDDlaptop)
            i = 1
        }
        if (DDDlike != null && i == 1 && CamPlay > .1){
            scene.add(DDDlike)
            i = 2
        }
        if (DDDmega != null && i == 2 && CamPlay > .28){
            scene.add(DDDmega)
            i = 3
        }
        if (DDDcamera != null && i == 3 && CamPlay > .500){
            scene.add(DDDcamera)
            i = 4
        }

        if (DDDeight != null && i == 4 && CamPlay > .705){
            scene.add(DDDeight)
            i = 5
        }
    }

    if (DDDlaptop != null && DDDlike != null  && DDDmega != null && DDDcamera != null && DDDeight != null){
        DDDlaptop.rotation.y += TimeDif * 0.0005
        DDDlike.rotation.y += TimeDif * 0.0005
        DDDmega.rotation.y += TimeDif * 0.0005
        DDDcamera.rotation.y += TimeDif * 0.0005
        DDDeight.rotation.y += TimeDif * 0.0005

        if (camera.position.y > -.5){
            scene.remove(DDDlaptop, DDDlike, DDDmega, DDDcamera, DDDeight)
        }
    }
    

    //Riples
    if (camera.position.y < -1.3 ) {
        scene.remove(Ripple)
    } else{ scene.add(Ripple) }

    //Office
    if (DDDoffice !== null){
        if (Scroll < 2000) {
            DDDoffice.position.z = (Scroll * .06) -200
            DDDoffice.position.y = (Scroll * .12) - 250
        }

        if(Scroll>2000 && Scroll <2500){
            DDDoffice.position.y = -10
        }
    } 

    



    // Update controls
    // controls.update()
    // console.log("Cam X:", camera.position.x)
    // // console.log("Tar X:", Target.position.x)
    // console.log("Cam Y:", camera.position.y)
    // // console.log("Tar Y:", Target.position.y)
    // console.log("Cam Z:", camera.position.z)
    
    // console.log("Tar Z:", Target.position.z)
    // console.log("Scroll:", Scroll)
    

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(VideoCapture)
}

VideoCapture()